<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="-1" :dialogVisible2="topDialogVisible"></top>
      <img src="../assets/image/banner4.png" width="100%" alt="" />
      <div class="container-box position-relative">
        <div class="textAlign-r btn-box">
          <el-button round @click="gotoFinancialServiceSign">银行申请入驻</el-button>
        </div>

        <div class="width-100 list-box">
          <el-row>
            <el-col class="el-col" :span="24" v-for="(item, index) in list" :key="index"
              ><div class="grid-content width-100 flex-row justify-content-around align-items-center">
                <div class="width-25">
                  <el-image v-if="item.image" :src="imagePrefix + item.image" fit="cover"></el-image>
                  <img v-else src="../assets/image/dome1.jpg" width="90%"/>
                </div>
                <div class="width-75 textAlign-l">
                  <p class="width-100 cut-text fs-big black fw-mid">{{ item.name }}</p>
                  <p class="width-100 cut-text2 darkgrey opacity-5 line-height margin-ts margin-bs">{{ item.blurb }}</p>
                  <div class="width-100 flex-row justify-content-spaceBetween align-items-center">
                    <p class="width-100 fs-mid black">
                      已有<span class="red fs-mid">{{ item.applyNum }}</span
                      >人咨询
                    </p>
                    <el-button
                      type="warning"
                      round
                      size="small"
                      @click="gotoFinancialServiceApplication(item.id)"
                      >申请质押</el-button
                    >
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { financeBankList } from '@/api/TranFinanceBank';

export default {
  name: 'financialService',
  components: {
    top,
    bottom
  },
  data() {
    return {
      topDialogVisible: false,
      imagePrefix: process.env.VUE_APP_IMAGE_PREFIX,
      list: []
    };
  },
  created() {
    financeBankList({ examineState: '2' }).then((res) => {
      this.list = res.rows;
    });
  },
  methods: {
    async gotoFinancialServiceSign() {
      if (localStorage.getItem('bqsb_login')) {
        // 登录存在
        this.$router.push('/financialServiceSign')
      }else {
        this.topDialogVisible = false;
        this.topDialogVisible = true;
      }
    },
    async gotoFinancialServiceApplication(id) {
      if (localStorage.getItem('bqsb_login')) {
        // 登录存在
        this.$router.push({ path: '/financialServiceApplication', query: { id } })
      }else {
        this.topDialogVisible = false;
        this.topDialogVisible = true;
      }
    },
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    top: -100px;
    .btn-box {
      padding-right: 8%;
      /deep/.el-button {
        background: rgb(236, 85, 80);
        color: white;
        padding: 12px 40px;
        //background: linear-gradient(ro right, rgb(236, 85, 80), rgb(228, 108, 112)) !important;
        border: none;
        span {
          font-size: 18px;
        }
      }
    }
    .list-box {
      margin-top: 100px;
      .el-col {
        padding: 10px;
      }
      .grid-content {
        background: white;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(133, 188, 240, 0.25);
        padding: 30px;
        .el-image {
          width: 90%;
          height: 140px;
        }
      }
      /deep/.el-button {
        background: #3f96ff;
        border: none;
      }
    }
  }
}
</style>
